<template>
  <div>
    <a href="#" target="_blank"><img src="@/assets/social/facebook.png" /></a>
    <a href="#" target="_blank"><img src="@/assets/social/twitter.png" /></a>
    <a href="#" target="_blank"><img src="@/assets/social/insta.png" /></a>
  </div>
</template>

<style scoped>
div {
  display: flex;
  align-items: center;
  font-size: 20px;
}
span {
  margin-right: 5px;
  font-weight: bold;
}
img {
  max-height: 22px;
}

div {
  display: flex;
  justify-content: center;
  gap: 3px;
}
</style>
